@use '../variables' as *;

.single-movie-container {

    position: relative;
    padding: 3rem ;
    background-color: rgba(black, 0.6);
    
    .favourite .like-icon {
        height: 1.5rem;
        width: 1.5rem;
        cursor: pointer;
        float: right;
        margin-top: 1.5rem;
    }

    .single-movie-poster,
    .single-movie-info {
        position: relative;
        z-index: 2;
    }

    .single-movie-poster {
        width: 60%;
        margin: 0 auto;
        padding-bottom: 2rem;
    }

    .single-movie-backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-size: cover;
        opacity: 0.2;
        z-index: 1;
    }

    h2 {
        text-align: center;
    }

    p {
        line-height: 1.4;
        font-size: 0.9rem;
    }

    @media (min-width: 800px){

        .single-movie-poster {
            flex: 0 0 250px;
        }

        .info-top {
            padding-top: 0;
        }

        h2 {
            font-size: 2rem;
            text-align: left;
            margin-top: 0.5rem;
        }

        .single-movie-info-container {
            display: flex;
            flex-direction:row-reverse ;
            align-items: flex-start;
            max-width: 1000px;
            padding: 2rem;
        }

        .single-movie-poster {
            padding-bottom: 0;
        }

        .single-movie-info {
            align-self: flex-start;
            margin-left: 3rem;
        }
    }

    @media (min-width: 900px){
        .single-movie-poster {
            flex: 0 0 300px;
        }

        .single-movie-info {
            margin-left: 4rem;
        }

        h2 {
            text-align: left;
        }
    }

    @media (min-width: 1100px){
        .single-movie-poster {
            flex: 0 0 350px;
        }
        .single-movie-info {
            margin-left: 5rem;
        }

        p {
            font-size: 1rem;
        }

        h2 {
            font-size: 2.5rem;
            text-align: left;
        }
    }
}

.single-movie-info-container {
    position: relative;
    width: 75%;
    max-width: 700px;
    margin: 0 auto;
    padding: 0 2rem;
    border-radius: 20px;
    background-color: #2E3544;

    .rating {
        border: 1px solid $textColor;
        padding: .1rem .4rem;
        display: inline-block;
        margin: 0;
    } 
}

.info-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 1rem;

    button {
        border: none;
        background: transparent;
        text-decoration: underline;
    }

    a {
        color: $textColor;
        text-decoration: none;
    }

    .rating-like {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
