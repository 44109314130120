$backgroundColor: #2E3544;
$brandColor: #BD2540;
$textColor: #F6EDE1;
$accentColor: #EDC139;

$breakpoint400: 25em;
$breakpoint520: 31em;
$breakpoint600: 38em;
$breakpoint800: 50em;
$breakpoint1000: 62em;
$breakpoint1300: 81em;
$breakpoint1500: 100em;
$breakpoint1800: 112em;