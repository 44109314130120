@use '../variables' as *;

.favourite-content,
.about-content {

    padding: 2rem;

    h1, p {
        text-align: center;
    }

    p {
        line-height: 1.5rem;
    }
    
    .no-movies-title {
        text-shadow: 1px 1px 2px $accentColor;
    }

    img {
        height: 1rem;
        padding: 0 .2rem;
    }

    .browse-movies a {
        text-decoration: none;
        color: $textColor;
        border: 1px solid $accentColor;
        padding: .5rem 1rem;
    }

    .browse-movies {
        margin: 3rem;
    }

    .tmdb-logo {
        height: 6rem;
        display: block;
        margin: 0 auto;
        margin-top: 5rem;
    }

    @media (min-width: $breakpoint520) {
        padding: 3.5rem;
    }
}