@use "../variables" as *;

.nav.closed {
    display: none;

    @media (min-width: $breakpoint520) {
        display: initial;
    }
}

.nav {
    position: absolute;
    top: 5rem;
    width: 100%;
    left: 0;

    @media (min-width: $breakpoint520) {
        position: initial;
        width: initial;
    }

    ul {
        padding: 0 2rem;
        list-style-type: none;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        @media (min-width: $breakpoint400) {
            padding: 0 3.5rem;
        }

        @media (min-width: $breakpoint520) {
            padding: 0;
        }

        .nav-item {
            text-transform: uppercase;
            text-decoration: none;
            color: $textColor;
            padding: 3px 6px;
            border: 1px solid transparent;

            @media (min-width: $breakpoint520) {
                
                font-size: 0.8rem;

                &:hover{
                    border-color: $accentColor;
                }

                // Yellow border to remain while on the page
                &.active {
                    border-color: $accentColor;
                }
            }

            @media (min-width: $breakpoint800) {
                font-size: 1rem;
            }

            @media (min-width: $breakpoint1000) {
                font-size: 1.2rem;
            }

            @media (min-width: $breakpoint1300) {
                font-size: 1.5rem;
            }
        }
    }
}

@media (min-width: $breakpoint520) {

    #hamburger-icon {
        display: none;
    }
}

// Hamburger Menu
#hamburger-icon {
    width: 60px;
    height: 45px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg) scale(0.5);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#hamburger-icon span {
    display: block;
    position: absolute;
    height: 9px;
    width: 100%;
    background: $textColor;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

#hamburger-icon span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#hamburger-icon span:nth-child(2) {
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#hamburger-icon span:nth-child(3) {
  top: 36px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#hamburger-icon.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}

#hamburger-icon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#hamburger-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 39px;
  left: 8px;
}