@use './variables' as *;
@use './fonts';
@use './normalize-fwd.scss';

@use './components/footer';
@use './components/header';
@use './components/movie-card';
@use  './components/nav';
@use './components/single-movie';

@use './pages/favourites';
@use './pages/page-home';

body {
    background-color: $backgroundColor;
    font-family: 'Raleway', sans-serif;
    color: $textColor;
    line-height: 1.4;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    main {
        flex: 1;
    }
}