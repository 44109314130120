@use '../variables' as *;

.card-container {

    padding: 2rem;

    @media (min-width: $breakpoint400) {
        & {
            padding: 3.5rem;
        }
    }

    .movie-poster {
        position: relative;

        img {
            height: auto;
            width: 100%;
            filter: brightness(30%);
        }

        .info {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            display: flex;
            flex-direction: column;

            .top-text-container {
                flex-grow: 1;
                margin: .5rem 1rem;

                .top-card {
                    display: flex;
                    flex-grow: 1;
                    justify-content: space-between;
                    align-items: center;
    
                    .rating {
                        border: 1px solid $textColor;
                        padding: .1rem .4rem;
                    }
                }
    
                .text {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
    
                    h1 {
                        margin: 0;
                        line-height: 1;
                        font-size: 1.5rem;

                        @media (min-width: $breakpoint400) {
                            & {
                                margin: 0.6rem 0;
                                font-size: 1.8rem;
                            }
                        }
                    }

                    p {
                        font-size: 0.8rem;

                        @media (min-width: $breakpoint400) {
                            & {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
            
            .button-container {
                display: block;
                margin: 0 auto;
                margin-bottom: 2rem;

                @media (min-width: $breakpoint400) {
                    & {
                        margin-bottom: 3rem;  
                    }
                }

                .button {
                    border: none;
                    font-family: 'Raleway';
                    font-style: normal;
                    font-weight: 300;
                    text-transform: uppercase;
                    background-color: $brandColor;
                    padding: .5rem;

                    @media (min-width: $breakpoint400) {
                        & {
                            padding: .6rem;
                        }
                    }

                    @media (min-width: $breakpoint520) {
                        &:hover {
                            background-color: $accentColor;
                        
                            a {
                                color: $backgroundColor;
                            }
                        }
                    }

                    a {
                        text-decoration: none;
                        color: $textColor;

                        @media (min-width: $breakpoint400) {
                            & {
                                font-size: 1;
                            }
                        }
                    }
                }
            }

            .like-icon {
                height: 2rem;
                width: 2rem;
                opacity: 1;
                filter: brightness(100%);
            }
        }
    }
}
